<template>
  <div>
    <Row class="p-b-10">
        <i-col span="24">
          <label>上传素材类型：</label>
          <RadioGroup size="small" v-model="screenType" type="button">
            <Radio v-for="item in screenTypes" :key="item.id" :label="item.id">{{item.name}}</Radio>
          </RadioGroup>
        </i-col>
      </Row>
    <Card title="素材">
      <Row v-if="disabled" class="p-b-5">
        <Progress :percent="percentage"></Progress>
      </Row>
      <Upload
        ref="upload"
        :show-upload-list="false"
        :before-upload="handleBeforeUpload"
        action=""
        multiple
        type="drag"
      >
        <div style="padding: 20px 0">
          <Icon
            type="ios-cloud-upload"
            size="52"
            style="color: #3399ff"
          ></Icon>
          <p>点击或拖拽文件到这里进行上传，只支持图片/视频类型</p>
        </div>
      </Upload>

      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="6"
          :lg="4"
          v-for="(image,imgIndex) in ImageList"
          :key="imgIndex"
        >
          <div class="demo-upload-list">
            <template>
              <img :src="getbasesrc(image)">
              <div class="demo-upload-list-cover">
                <Icon
                  type="ios-eye-outline"
                  @click.native="handleView(image,1)"
                ></Icon>
                <Icon
                  type="ios-trash-outline"
                  @click.native="handleRemove(image,1)"
                ></Icon>
              </div>
            </template>
          </div>
          <!-- <div>
                  <Progress :percent="image.percentage"></Progress>
                </div> -->

        </i-col>
      </Row>
      <Row :gutter="8">
        <i-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="(video,videoIndex) in VideoList"
          :key="videoIndex"
        >
          <div class="demo-upload-list">
            <template>
              <a style="word-wrap: break-word;width:100%;height:60px;">{{video.name}}</a>
              <div class="demo-upload-list-cover">
                <Icon
                  type="ios-eye-outline"
                  @click.native="handleView(video,2)"
                ></Icon>
                <Icon
                  type="ios-trash-outline"
                  @click.native="handleRemove(video,2)"
                ></Icon>
              </div>
            </template>
          </div>
        </i-col>
      </Row>
    </Card>

    <Card
      class="m-t-10"
      title="资质材料"
    >
      <Upload
        multiple
        type="drag"
        :before-upload="handleBeforeUploadFile"
        action=""
        :format="['jpg','jpeg','png','pdf','doc','docx','xls','xlsx']"
      >
        <div style="padding: 20px 0">
          <Icon
            type="ios-cloud-upload"
            size="52"
            style="color: #3399ff"
          ></Icon>
          <p>点击或拖拽文件到这里进行上传</p>
        </div>
      </Upload>
      <Row v-show="fileList.length">
        <i-col span="16"><b>文件名称</b></i-col>
        <i-col span="6"><b>文件大小</b></i-col>
        <i-col span="2"><b>操作</b></i-col>
        <hr>
      </Row>

      <Row
        v-for="(file,fileIndex) in fileList"
        :key="fileIndex"
        v-show="fileList.length"
      >
        <i-col span="16">{{file.name}}</i-col>
        <i-col span="6">{{(file.size/1024).toFixed(2)}}KB</i-col>
        <i-col span="2"><a
            class="delete"
            @click="handleRemove(file,3)"
          >删除</a></i-col>
        <i-col span="24">
          <hr>
        </i-col>
      </Row>
    </Card>

    <div class="m-t-10">
      <Card>
        <p
          slot="title"
          style="color:#fff"
        >文件上传要求</p>
        <p style="color:#FA8B59">1、图片大小限制20M，上传格式为：.jpg .png .jpeg .img</p>
        <p style="color:#FA8B59">2、视频大小70M，上传格式为：.mp4 .wmv</p>
        <p style="color:#FA8B59">3、资质文件上传格式为：.jpg .png .jpeg .pdf .doc .docx .xls .xlsx</p>
      </Card>
    </div>
    <div class="text-right m-t-10">
      <Button
        type="success"
        v-if="!isEdit"
        @click="confirmUpload"
        :disabled="isUploadFiles"
      >确认上传</Button>
    </div>

    <!-- 上刊材料预览（画面、视频） -->
    <Modal
      title="画面预览"
      v-model="visibleImage"
      :footer-hide="true"
    >
      <img
        :src="ImageUrl"
        v-if="visibleImage"
        style="width: 100%"
      >
    </Modal>
    <Modal
      title="视频预览"
      v-model="visibleVideo"
      :footer-hide="true"
    >
      <video
        :src="VideoUrl"
        controls="controls"
        style="width:100%"
      >
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <!-- 上刊材料预览（画面、视频） -->

    <Modal
      v-model="showConfirmUpload"
      width="400"
    >
      <p
        slot="header"
        style="color:#ff9900;text-align:center"
      >
        <Icon type="ios-information-circle"></Icon>
        <span>上传提示</span>
      </p>
      <p><strong>请注意，上传文件后将立即提交管理审批！”</strong></p>
      <p class="p-t-10">上传备注信息：</p>
      <p>
        <i-input
          maxlength="500"
          v-model="uploadDesc"
          show-word-limit
          type="textarea"
          :rows="4"
          placeholder="请输入上传备注信息，非必填..."
          style="width: 320px"
        />
      </p>
      <div slot="footer">
        <Button
          type="info"
          size="large"
          long
          :loading="modal_loading"
          @click="handleUpload"
        >开始上传</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'

export default {
  props: {
    relatedId: {
      type: [Number, String],
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      screenTypes: [{
        id: 1,
        name: '公益素材'
      }, {
        id: 2,
        name: '招商素材'
      }],
      screenType: 1,
      orderId: 970,
      disabled: false,
      modal_loading: false,
      // uploadFieldName: 'imgFiles', // 上传文件字段名
      // uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-order/v1/orderfile/crateorderfileapproval`,
      // headers: {
      //   'ooh-auth': getStorage('ooh-token')
      // }, // 上传组件设置协议头
      // params: {
      //   orderId: this.orderId
      // },
      maxSize: 20480,
      ImageList: [],
      VideoList: [],
      fileList: [],
      showConfirmUpload: false,
      visibleImage: false,
      ImageUrl: '',
      visibleVideo: false,
      VideoUrl: '',
      showImgPercentage: false, // 图片，视频进度显示
      percentage: 0, // 图片，视频上传进度
      uploadDesc: '' // 上传备注
    }
  },
  methods: {
    handleBeforeUpload (file) {
      if (file.type.indexOf('image/') > -1) {
        if (file.size > 20971520) { // 单个图片文件不超过30M
          this.$Notice.warning({ desc: '文件过大，单个图片文件最大限制20M' })
          return false
        }
        this.ImageList.push(file)
      } else if (file.type.indexOf('video/') > -1) {
        if (file.size > 73400320) { // 单个视频文件不超过70M
          this.$Notice.warning({ desc: '文件过大，单个视频文件最大限制70M' })
          return false
        }
        this.VideoList.push(file)
      }
      return false
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },

    getbasesrc (file) {
      return window.URL.createObjectURL(file)
    },
    handleView (file, fileType) {
      if (fileType === 1) {
        this.ImageUrl = this.getbasesrc(file)
        this.visibleImage = true
      } else if (fileType === 2) {
        this.VideoUrl = this.getbasesrc(file)
        this.visibleVideo = true
      }
      //   this.priviewFile = file
      //   this.visible = true
    },
    handleRemove (file, fileType) {
      if (fileType === 1) {
        this.ImageList.splice(this.ImageList.indexOf(file), 1)
      } else if (fileType === 2) {
        this.VideoList.splice(this.VideoList.indexOf(file), 1)
      } else if (fileType === 3) {
        this.fileList.splice(this.fileList.indexOf(file), 1)
      }
    },
    confirmUpload () { // 开始上传问题
      this.showConfirmUpload = true
      this.uploadDesc = ''
    },
    handleUpload (editBean) {
      const _this = this
      _this.percentage = 0
      _this.disabled = true
      _this.modal_loading = true
      _this.showConfirmUpload = false

      const param = new FormData()
      param.append('type', _this.screenType)
      _this.ImageList.forEach(function (file, index) {
        param.append('imgFiles', file)
      })
      _this.VideoList.forEach(function (file, index) {
        param.append('vedioFiles', file)
      })
      _this.fileList.forEach(function (file, index) {
        param.append('qualificationFiles', file)
      })
      if (this.uploadDesc !== '') { // 上传备注
        param.append('desc', this.uploadDesc)
      }
      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/file/uploadfilewithapproval',
        param,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true,
          onUploadProgress (progress) { // 处理上传文件进度条数据
            _this.percentage = Math.round(progress.loaded / progress.total * 100)
          }
        }
      ).then(res => {
        if (res && res.data && !res.data.errcode) {
          _this.ImageList = []
          _this.VideoList = []
          _this.fileList = []
          _this.percentage = 0

          _this.$Notice.success({ desc: '上传成功' })
          _this.disabled = false
          _this.modal_loading = false
        } else {
          this.$Notice.error({
            desc: res.data.errmsg
          })
          _this.disabled = false
          _this.modal_loading = false
        }
      })
    }
  },
  computed: {
    isUploadFiles () {
      const fileCount = this.ImageList.length + this.VideoList.length
      return fileCount === 0 || this.disabled
    }
  }
}
</script>

<style scoped>
.demo-upload-list {
  display: inline-block;
  width: 120px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
